exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-evaluation-js": () => import("./../../../src/pages/evaluation.js" /* webpackChunkName: "component---src-pages-evaluation-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-intercultural-bridge-transformation-js": () => import("./../../../src/pages/intercultural-bridge-transformation.js" /* webpackChunkName: "component---src-pages-intercultural-bridge-transformation-js" */),
  "component---src-pages-interpretation-js": () => import("./../../../src/pages/interpretation.js" /* webpackChunkName: "component---src-pages-interpretation-js" */),
  "component---src-pages-languages-js": () => import("./../../../src/pages/languages.js" /* webpackChunkName: "component---src-pages-languages-js" */),
  "component---src-pages-legalization-js": () => import("./../../../src/pages/legalization.js" /* webpackChunkName: "component---src-pages-legalization-js" */),
  "component---src-pages-monitoring-js": () => import("./../../../src/pages/monitoring.js" /* webpackChunkName: "component---src-pages-monitoring-js" */),
  "component---src-pages-patent-infringement-services-js": () => import("./../../../src/pages/patent-infringement-services.js" /* webpackChunkName: "component---src-pages-patent-infringement-services-js" */),
  "component---src-pages-patent-translation-js": () => import("./../../../src/pages/patent-translation.js" /* webpackChunkName: "component---src-pages-patent-translation-js" */),
  "component---src-pages-patent-validity-services-js": () => import("./../../../src/pages/patent-validity-services.js" /* webpackChunkName: "component---src-pages-patent-validity-services-js" */),
  "component---src-pages-product-theft-js": () => import("./../../../src/pages/product-theft.js" /* webpackChunkName: "component---src-pages-product-theft-js" */),
  "component---src-pages-strategy-services-js": () => import("./../../../src/pages/strategy-services.js" /* webpackChunkName: "component---src-pages-strategy-services-js" */),
  "component---src-pages-translations-js": () => import("./../../../src/pages/translations.js" /* webpackChunkName: "component---src-pages-translations-js" */),
  "component---src-pages-why-contract-us-js": () => import("./../../../src/pages/why-contract-us.js" /* webpackChunkName: "component---src-pages-why-contract-us-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */)
}

